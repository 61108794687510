<template>
  <div>
    <div class="contact-content">
      <div class="contact-bg">
      </div>
      <div class="yx">
        <p>
          <img src="@/assets/images/contact/dz.png" alt="">
          地址：湖北省武汉市东湖新技术开发区光谷SBI创业街9号楼2F-208A
        </p><br>
        <p>
          <img src="@/assets/images/contact/yb.png" alt="">
          邮编：430000
        </p><br>
        <p>
          <img src="@/assets/images/contact/yx.png" alt="">
          邮箱：gaohuanghust@126.com
        </p><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.contact-bg {
  background: url(../../assets/images/contact/bg.jpg);
  background-size: cover;
  position: relative;
  height: 562px;
}
.contact-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: .9;
  clip-path: polygon(0 0, 70% 0, 52% 100%, 0 100%);
}
.contact-content {
  position: relative;
  .yx {
    position: absolute;
    top: 160px;
    left: 330px;
    img {
      width: 24px;
      vertical-align: middle;
    }
  }
}
</style>